textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: #7c162e;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #7c162e;
    outline: 0 none;
}

.webkitScroll::-webkit-scrollbar {
    width: 0;
}

.form-check-input:checked {
    background-color: #FCD53F;
    border-color: #FCD53F;
}

.form-check-input:focus {
    border: none;
}

.form-check-input {
    background-color: #00D26A;
    border-color: #00D26A;
}

.switch-container > div > input {
    margin-right: 5px;
}

.modalController .modal-content {
    background-color: rgba(255, 255, 255, 0);
}

.modalController .modal-footer {
    border: none;
    justify-content: center;
}

.border-silver-card {
    border: 5px solid #757575;
}

.border-golden-card {
    border: 5px solid #ffb845;
}

button.border-isExpand-card {
    border-color: #044a19;
    border-top: 0;
}

button.border-isExpand-card:hover {
    border-color: #044a19;
    border-top: 0;
}

button.border-notExpand-card {
    border-width: 2px;
    border-color: white;
}

button.border-notExpand-card:hover {
    border-color: white;
}

.border-isExpand-card {
    border: 5px solid #044a19;
}